import React from "react"
// import BlockContent from "@sanity/block-content-to-react"

import slug from "slug"

/* Import Global Hook(s) */
import { useHotelPage } from "~queries/hooks"
import { useHomePage } from "~queries/hooks"

/* Import Global Component(s) */
import BlockContent from "~components/blockContent/blockContent"
import Footer from "~components/footer/footer"
import Page from "~components/page/page"
import PageHeader from "~components/page/components/pageHeader/pageHeader"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"
import ImageGrid from "~components/imageGrid/imageGrid"
import Slideshow from "~components/slideshow/slideshow"
import BlockMatrix from "~components/global/blockMatrix"

import AlertButton from "~components/pages/index/components/alertButton/alertButton"

/* Import Local Style(s) */
import "./hotels.scss"

const checkA = a => { return (Array.isArray(a) && a.length > 0) ? true : false }

const Travel = ({ location }) => {
  const { pageTitle, _rawHotel, slideshow, hotelAlert, hotelBlocks } = useHotelPage()

  return (
    <Page title={pageTitle} location={location} className="page--hotels">
      <PageHeader title={pageTitle} location={location} />
      <PageArticle title={pageTitle} className="hotels">
        <PageArticleSection className="hotels">
          <div className="hotels-description">
            <BlockContent blocks={_rawHotel} />
          </div>

          { checkA(hotelAlert) && <div className="mt--2 mb--2">
            { hotelAlert.map(a => (<AlertButton key={slug(a.title)} title={a.title} link={a.link} hex={a.bcolor.hex} isMailingList={a.isMailingList} />)) }
          </div>}

          {slideshow.images.length > 0 ? (
            <div><Slideshow slideshow={slideshow} /></div>
          ) : null}

          



          {hotelBlocks && hotelBlocks.length > 0 && <BlockMatrix blocks={hotelBlocks} />}


        </PageArticleSection>
      </PageArticle>
      <Footer />
    </Page>
  )
}

export default Travel
