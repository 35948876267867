import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"

import Card from "~components/card/card"

/* Import Local Style(s) */
import "./alert-button.scss"

/* mailing list styles */


import CrossSvgSrc from "~assets/images/close.svg"
import MailingListModal from "~components/footer/components/mailingListModal/mailingListModal"
/* Import Global Animation(s) */
import elementTransitionAnimation from "~animations/elementTransition"

const AlertButton = ({hex, title, link, isMailingList}) => {

  const [isActive, setIsActive] = useState(false)

  const handleClick = () => { setIsActive(true) }
  const closeModal = () => { setIsActive(false) }

  if(isMailingList){
    return (
      <div className="alert-buttons_wrapper">
        <span className="alert-buttons" onClick={handleClick}>
          <Card backgroundColor={hex}>
            <p className="font-size--small">{title}</p>
          </Card>
        </span>

        {isActive && <AnimatePresence exitBeforeEnter>
          <motion.div
            key="mailinglist"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={elementTransitionAnimation.variants}
          >
          <div className="mailing-list--modal">
            <button className="mailing-list_close" onClick={closeModal}>
              <img
                src={CrossSvgSrc}
                className="close"
                alt="cross"
                key="cross"
                exit="exit"
              />
            </button>
            <MailingListModal />          
            </div>
          </motion.div>
          
        </AnimatePresence>}

      </div>
    )
  }else{
    return (<a
      href={link}
      className="alert-buttons"
      target="_blank"
      rel="noreferrer"
    >
      <Card backgroundColor={hex}>
        <p className="font-size--small">{title}</p>
      </Card>
    </a>)
  }

  
}

export default AlertButton
